import * as React from 'react';
import { isEmpty } from 'lodash';
import { generateSlug } from '../slug';
import { decodingContent } from '../decodingContent';
import { useRouter } from 'next/router';

export function useHash(tabTitles) {
  const [hashes, setHashes] = React.useState<string[]>();
  const [matchIndex, setMatchIndex] = React.useState<number>(-1);
  const [mounted, setMounted] = React.useState<boolean>();
  const router = useRouter();

  React.useEffect(() => {
    const hash = router.asPath.split('#')?.[1];
    if (hash) {
      setHashes(decodeURIComponent(hash).split('|'));
    }

    setMounted(true);
  }, [router.asPath]);

  React.useEffect(() => {
    if (hashes && !isEmpty(tabTitles)) {
      const isMatch: number = tabTitles.findIndex((t) => {
        const title = typeof t === 'string' ? t : t.title;
        const titlesIndex = hashes.findIndex(
          (hash) => hash === generateSlug(decodingContent(title)),
        );

        if (titlesIndex > -1) {
          return true;
        }

        return false;
      });

      if (isMatch > -1) {
        setMatchIndex(isMatch);
      } else setMatchIndex(-1);
    } else if (!hashes && mounted) {
      setMatchIndex(-1);
    }
  }, [hashes, mounted]);

  return { matchIndex, hashes };
}
